








import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Profile } from 'client-website-ts-library/types/Profile';

import ProfileCard from './ProfileCard.vue';

@Component({
  components: {
    ProfileCard,
  },
})
export default class ProfileCards extends Vue {
  @Prop({ default: [] })
  private readonly profiles!: Profile[];

  @Prop({ default: 4 })
  private readonly perRow!: number;

  @Prop({ default: 4 })
  private readonly page!: number;

  @Prop({ default: '/people/' })
  private readonly href!: boolean;
}
